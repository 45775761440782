import React from 'react'
import { Layout } from '@common'
import { BlogSection,  
	NewsletterSection, AttentionSection, 
	CategoriesSection, ProductsSection } from '@sections'

const IndexPage = ({ pageContext }) => {
	return (
		<Layout animation={false}
			seo={pageContext.page.seo}
			siteMetaData={pageContext.page.siteMetaData}
		>
			<AttentionSection />
			<ProductsSection slider={true} recommended={true} products={pageContext.productsWithDetails} maxCount={4}
			/>
			<BlogSection slider={true} posts={pageContext.posts} />
			<CategoriesSection slider={true} categories={pageContext.productCategories} slider={true} />
			<NewsletterSection />
		</Layout>
	)
}

export default IndexPage
